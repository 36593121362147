// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.wrapper {
        width: 500px;
}

.progress-bar {
       
        width: 100%;   
        background-color: #e0e0e0;
        padding: 53px 0px 0px 0px;
	display: block;
        border-radius: 3px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);
}
                
.progress-bar-fill {
        display: block;
        height: 22px;
        background-color: #659cef;
        border-radius: 3px;
        
        transition: width 500ms ease-in-out;
}

`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":";AACA;QACQ,YAAY;AACpB;;AAEA;;QAEQ,WAAW;QACX,yBAAyB;QACzB,yBAAyB;CAChC,cAAc;QACP,kBAAkB;QAClB,6CAA6C;AACrD;;AAEA;QACQ,cAAc;QACd,YAAY;QACZ,yBAAyB;QACzB,kBAAkB;;QAElB,mCAAmC;AAC3C","sourcesContent":["\n.wrapper {\n        width: 500px;\n}\n\n.progress-bar {\n       \n        width: 100%;   \n        background-color: #e0e0e0;\n        padding: 53px 0px 0px 0px;\n\tdisplay: block;\n        border-radius: 3px;\n        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .2);\n}\n                \n.progress-bar-fill {\n        display: block;\n        height: 22px;\n        background-color: #659cef;\n        border-radius: 3px;\n        \n        transition: width 500ms ease-in-out;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
