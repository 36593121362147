import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { encrypt, decrypt } from './encryption';

const API_URL = '/api';

const api = axios.create({
  baseURL: API_URL,
  beforeRedirect: (opts, res) => {
    opts.headers = {
      "Authorization": `Bearer ${localStorage.getItem('token')}`,
    }
  },
 headers: {
    "Authorization": `Bearer ${localStorage.getItem('token')}`,
  },
/*
  transformRequest: [(data) => {
    return JSON.stringify(data);
  }, ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, (data) => {
    return JSON.parse(data);
  }],
  transformRequest: [(data) => {
    return encrypt(data);
  }, ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, (data) => {
    return decrypt(data);
  }],
*/
});


const getToken = () => localStorage.getItem('token');
const setToken = (token) => localStorage.setItem('token', token);
const removeToken = () => localStorage.removeItem('token');


let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

const refreshedToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  try {
    const response = await axios.post(`${API_URL}/refresh-token`, { refresh_token: refreshToken });
    const { access_token, refresh_token } = response.data;
    localStorage.setItem('token', access_token);
    localStorage.setItem('refreshToken', refresh_token);
    return access_token;
  } catch (error) {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    throw error;
  }
};

const redirectToLogin = () => {
  // Clear all auth tokens
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  
  // Redirect to login page
  window.location.href = '/login';
};



/*
const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  try {
    const response = await api.post('/refresh-token', { refresh_token: refreshToken });
    const { access_token } = response.data;
    localStorage.setItem('token', access_token);
    return access_token;
  } catch (error) {
console.log('boo');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    throw error;
  }
};
*/



api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({resolve, reject});
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return api(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await api.post('/refresh-token', { refresh_token: refreshToken });
        const { access_token } = response.data;
        localStorage.setItem('token', access_token);
        api.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        processQueue(null, access_token);
        return api(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.dispatchEvent(new CustomEvent('logout'));
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

api.setToken = (token) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

api.refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  const response = await api.post('/refresh-token', { refresh_token: refreshToken });
  return response.data;
};


api.wrap = (promise) => {
    let status = 'pending';
    let result;
    let suspender = promise.then(
      (r) => {
        status = 'success';
        result = r;
      },
      (e) => {
        status = 'error';
        result = e;
      }
    );
    return {
      read() {
        if (status === 'pending') {
          throw suspender;
        } else if (status === 'error') {
          throw result;
        } else if (status === 'success') {
          return result;
        }
      }
  }
}

export default {
  checkAuth: async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }

    try {
      const response = await api.get('/check-auth');
      return response.status === 200;
    } catch (error) {
      console.error('Auth check failed:', error);
      return false;
    }
  },

  getBuildings: async () => {
    const response = await api.get('/buildings');
    return response.data;
  },
  logout: async () => {
    try {
      await api.post('/logout');  // Assuming you have a logout endpoint to invalidate the token server-side
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      removeToken();
      localStorage.removeItem('refreshToken');
    }
  },
  login: async (username, password) => {
    const response = await api.post('/login', { username, password });
    setToken(response.data.access_token);
    console.log(response.data.access_token);
    localStorage.setItem('refreshToken', response.data.refresh_token);
    return response.data;
  },
  getUserData: async () => {
    try {
      const response = await api.get('/user');
       return response.data;
     } catch (error) {
      console.error('Failed to fetch user data:', error);
      throw error;
     }
   },

  getBuildings: async () => {
    const response = await api.get('/buildings');
    return response.data;
  },
  getInspections: async (buildingId) => {
    const response = await api.get(`/buildings/${buildingId}/inspections`);
    return response.data;
  },
  getUnitInspections: async (unitId) => {
    const response = await api.get(`/units/${unitId}/inspections`);
    return response.data;
  },
  getBuildingInspections: async (buildingId) => {
    const response = await api.get(`/buildings/${buildingId}/inspections`);
    return response.data;
  },
  getUnits: async (buildingId) => {
    const response = await api.get(`/buildings/${buildingId}/units`);
    return response.data;
  },
  getQuestions: async () => {
    const response = await api.get('/questions');
    return response.data;
  },
  getBuildingData: async (buildingId) => {
    const [buildingResponse, unitsResponse, inspectionsResponse] = await Promise.all([
      api.get(`/buildings/${buildingId}`),
      api.get(`/buildings/${buildingId}/units`),
      api.get(`/buildings/${buildingId}/inspections`)
    ]);
    return {
      building: buildingResponse.data,
      units: unitsResponse.data,
      inspections: inspectionsResponse.data
    };
  },
  createInspection: async (buildingId, jsonData) => {
    const response = await api.post(`/buildings/${buildingId}/inspections`, jsonData, {
      headers: { 'Content-Type': 'application/json' }
    });
    return response.data.id;
  },
  getBuildingData: async (buildingId) => {
    const response = await api.get(`/buildings/${buildingId}`);
    return response.data;
  },
  saveResponse: async (inspectionId, questionId, answer) => {
    await api.post(`/inspections/${inspectionId}/responses`, { question_id: questionId, answer });
  },
  uploadImage: async (inspectionId, image, description, unitNumber) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('description', description);
    formData.append('unitNumber', unitNumber);
    await api.post(`/inspections/${inspectionId}/images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },

  getLatestInspection: async (unitId) => {
    try {
      const response = await api.get(`/units/${unitId}/inspections`);
      const inspections = response.data;
      if (inspections.length === 0) {
        return null;
      }
      // Sort inspections by date in descending order and get the most recent one
      const latestInspection = inspections.sort((a, b) => new Date(b.date) - new Date(a.date))[0];
      // Fetch responses for the latest inspection
      const responsesResponse = await api.get(`/inspections/${latestInspection.id}/responses`);
      latestInspection.responses = responsesResponse.data;
      return latestInspection;
    } catch (error) {
      console.error('Error fetching latest inspection:', error);
      return null;
    }
  },
 
  getLatestInspectionForBuilding: async (buildingId) => {
    try {
      const response = await api.get(`/buildings/${buildingId}/latest_inspection`);
      return response.data;
    } catch (error) {
      console.error('Error fetching latest building inspection:', error);
      return null;
    }
  },

  getAllInspections: async () => {
    try {
      const response = await api.get('/inspections');
      return response.data;
    } catch (error) {
      console.error('Error fetching all inspections:', error);
      throw error;
    }
  },

  getBuildings: async () => {
    const response = await api.get('/buildings');
    return response.data;
  },
  fetchBuildings: async () => {
    return api.wrap(api.get('/buildings').then(response => response.data));
  },
  getInspections: async (buildingId) => {
    const response = await api.get(`/buildings/${buildingId}/inspections`);
    return response.data;
  },
  getUnitInspections: async (buildingId, unitId) => {
    const response = await api.get(`/buildings/${buildingId}/units/${unitId}/inspections`);
    return response.data;
  },
  getUnits: async (buildingId) => {
    const response = await api.get(`/buildings/${buildingId}/units`);
    return response.data;
  },
  getQuestions: async () => {
    const response = await api.get('/questions');
    return response.data;
  },
  createInspection: async (buildingId, formData) => {
    const serializedFormData = new FormData();
    for (let [key, value] of formData.entries()) {
      if (typeof value === 'object' && !(value instanceof File)) {
        serializedFormData.append(key, JSON.stringify(value));
      } else {
        serializedFormData.append(key, value);
      }
    }
    const response = await api.post(`/buildings/${buildingId}/inspections`, serializedFormData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data.id;
  },
  saveResponse: async (inspectionId, questionId, answer) => {
    await api.post(`/inspections/${inspectionId}/responses`, { question_id: questionId, answer });
  },
  uploadIssue: async (formData) => {
    const response = await api.post('/issues', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  },

  uploadIssueImages: async (issueId, formData) => {
    const response = await api.post(`/issues/${issueId}/images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  },

  getLatestInspection: async (unitId) => {
    try {
      const response = await api.get(`/units/${unitId}/latest_inspection`);
      return response.data;
    } catch (error) {
      console.error('Error fetching latest inspection:', error);
      return null;
    }
  },
  getLatestInspectionForBuilding: async (buildingId) => {
    try {
      const response = await api.get(`/buildings/${buildingId}/latest_inspection`);
      return response.data;
    } catch (error) {
      console.error('Error fetching latest building inspection:', error);
      return null;
    }
  }

};


