// Auto-generated by https://github.com/react-spring/gltfjsx

import * as THREE from "three"
import { useState, forwardRef, useLayoutEffect, useEffect, useRef } from "react"
import { useThree, useFrame } from "@react-three/fiber"
import { useGLTF, useTexture, useAnimations } from "@react-three/drei"
import { getMouseDegrees } from "./utils/mouse"

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { VRMLoaderPlugin, VRMUtils } from '@pixiv/three-vrm'
import { Object3D } from 'three'
import { useControls } from 'leva'



function moveJoint(mouse, joint, degreeLimit = 40) {
  let degrees = getMouseDegrees(mouse.x, mouse.y, degreeLimit)
  joint.rotation.xD = THREE.MathUtils.lerp(joint.rotation.xD || 0, degrees.y, 0.1)
  joint.rotation.yD = THREE.MathUtils.lerp(joint.rotation.yD || 0, degrees.x, 0.1)
  joint.rotation.x = THREE.MathUtils.degToRad(joint.rotation.xD)
  joint.rotation.y = THREE.MathUtils.degToRad(joint.rotation.yD)
}

export default function Model(props) {
  const group = useRef()
/*
  const texture = useTexture("/stacy.jpg")
  const { nodes, animations } = useGLTF("/stacy.glb")
  const { actions } = useAnimations(animations, group)
*/


  const { neck, hips } = useControls({
    neck: { value: 0, min: -1, max: 1 },
    hips: { value: 0, min: -1, max: 1 }
  })
  const { size, scene, camera } = useThree()
  //const gltf = useGLTF('/assets/astrovrm2.vrm')
  const avatar = useRef()
  const [bonesStore, setBones] = useState({})
  var gltf_loaded = '';

  useEffect(() => { 

/*	
	if (gltf) {

	VRMUtils.removeUnnecessaryJoints(gltf.scene)
	VRM.from(gltf).then((vrm) => {
	  avatar.current = vrm
	  vrm.lookAt.target = camera


	  //setBones(bones)
	}) */

                        // gltf and vrm
                        const loader = new GLTFLoader();
                        loader.crossOrigin = 'anonymous';

                        loader.register( ( parser ) => {

                                // assigning `helperRoot` to options will make helpers appear
                                return new VRMLoaderPlugin( parser); // , { helperRoot } ); // neon show

                        } );

                        loader.load(

                                // URL of the VRM you want to load
                                //'./models/VRM1_Constraint_Twist_Sample.vrm',
                                //'./assets/astrovrm2.vrm',
                                //'./assets/untitled-icos.vrm',
                                //'./assets/combohead1.vrm',
                                //'./assets/avaturn_avatar.vrm',
                                './assets/VU-VRM-elf.vrm',

                                // called when the resource is loaded
                                ( gltf ) => {
                                        // calling these functions greatly improves the performance
                                        const vrm = gltf.userData.vrm;
					VRMUtils.rotateVRM0(vrm)
                                        VRMUtils.removeUnnecessaryVertices( gltf.scene );
                                        VRMUtils.combineSkeletons( gltf.scene );
                                        VRMUtils.combineMorphs( vrm );

					
					gltf_loaded = gltf
					avatar.current = vrm
					vrm.lookAt.target = camera

					const hips = vrm.humanoid.getNormalizedBoneNode( 'hips' )
					const neck = vrm.humanoid.getNormalizedBoneNode( 'neck' )
					const spine = vrm.humanoid.getNormalizedBoneNode( 'spine' )
console.log('spine', spine)

					const bones = {
						hips: hips,
						neck: neck 
					}
					setBones(bones);

					

                                        // calling this function greatly improves the performance
                                        //VRMUtils.removeUnnecessaryVertices( gltf.scene );
                                        //VRMUtils.removeUnnecessaryJoints( gltf.scene );
                                        

                                        // Disable frustum culling
                                        vrm.scene.traverse( ( obj ) => {

                                                obj.frustumCulled = false;

                                        } );

                                        console.log( vrm, 'gltf', gltf );
                                        scene.add( vrm.scene );

                                },

                                // called while loading is progressing
                                ( progress ) => { 
                                     var total_progress = 100.0 * ( progress.loaded / progress.total )
                                     if (total_progress > 50 && parseInt(total_progress) % 10 == 0) {
                                              console.log( 'Loading model...', total_progress, '%' )
                                     }
                                     document.getElementById("progress-bar").style.width = total_progress.toString() + "%";

                                },

                                // called when loading has errors
                                ( error ) => console.error( error )

                        );



	console.log('valid gltf')
//}	

//	void actions.idle.play() 

  }, [scene, camera])

  useFrame((state, delta) => {
    const mouse = { x: size.width / 2 + (state.mouse.x * size.width) / 2, y: size.height / 2 + (-state.mouse.y * size.height) / 2 }
    const t = state.clock.getElapsedTime()
    if (avatar.current) {
	avatar.current.update(delta)
    }

    if (bonesStore.neck) {
		//console.log(bonesStore.neck.position)
   		//moveJoint(mouse, bonesStore.neck)

      bonesStore.neck.rotation.y = (Math.PI / 4) * Math.sin(t * Math.PI)
    }

    //moveJoint(mouse, nodes.mixamorigNeck)
    //moveJoint(mouse, nodes.mixamorigSpine)
  })

//console.log(avatar.current);

console.log(gltf_loaded, 'load')
  return (
    <mesh {...props} receiveShadow>
        <circleGeometry args={[8, 64]} />
    </mesh>

/*{
    <group rotation={[Math.PI / 2, 0, 0]} ref={group} {...props} dispose={null}>
      <primitive object={gltf_loaded.scene} />

      <primitive object={nodes["mixamorigHips"]} />
      <skinnedMesh receiveShadow castShadow geometry={nodes["stacy"].geometry} skeleton={nodes["stacy"].skeleton}>
        <meshStandardMaterial map={texture} map-flipY={false} skinning />
      </skinnedMesh>
    </group>
} */ 
  )
/*
  return <primitive object={gltf.scene}></primitive>
*/
}

