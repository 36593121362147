import React, { Suspense, useState, useRef, useEffect, lazy } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Sky, Stars, Cloud } from '@react-three/drei';
import { EffectComposer, Bloom, ChromaticAberration } from '@react-three/postprocessing';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import { AuthProvider, useAuth } from './utils/AuthContext';
import api from './utils/api';

const CityView = lazy(() => import('./components/CityView'));
const Building = lazy(() => import('./components/Building'));
const UI = lazy(() => import('./components/UI'));
const Login = lazy(() => import('./components/Login'));
const InspectionForm = lazy(() => import('./components/InspectionForm'));

const theme = responsiveFontSizes(createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#51E2F5',
    },
    secondary: {
      main: '#9DF9EF',
    },
  },
}));

function PrivateRoute({ children }) {
  const { isAuthenticated, isLoading, authCheckComplete } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (authCheckComplete && !isAuthenticated) {
      navigate('/login');
    }
  }, [authCheckComplete, isAuthenticated, navigate]);

  if (!authCheckComplete || isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return isAuthenticated ? children : null;
}

function Scene({ canvasRef, selectedBuilding, setSelectedBuilding }) {

  return (
    <Canvas ref={canvasRef} camera={{ position: [0, 20, -5], fov: 60 }}>
      <OrbitControls makeDefault minDistance={3} maxDistance={20} />     
      <Sky sunPosition={[100, 10, 100]} />
      <Stars radius={300} depth={50} count={1000} factor={4} saturation={0} fade />
      <Cloud opacity={0.5} speed={0.4} width={10} depth={1.5} segments={10} position={[0,-3, 0]} />
      
      <ambientLight intensity={0.7} />
      <pointLight position={[5, 5, 5]} intensity={0.5} />
      
      <directionalLight 
        position={[3, 3, 3]} 
        intensity={0.7} 
        castShadow
        shadow-mapSize-width={512}
        shadow-mapSize-height={512}
      />
      
	  {selectedBuilding ? (
	    <Building buildingId={selectedBuilding} scale={[1.8,1.8,1.8]} setSelectedBuilding={setSelectedBuilding}
		      isSelected={true} position={[0,0,5]} />
	  ) : (
     	    <CityView setSelectedBuilding={setSelectedBuilding} scale={[0.8,0.8,0.8]} position={[0,0,-15]} highlightColor="orange" />
	  )}
      { /* buildings.map((building) => (
        <Building 
          key={building.id} 
          buildingId={building.id} 
          setSelectedBuilding={setSelectedBuilding} 
          isSelected={selectedBuilding === building.id}
          position={[building.x, building.y, building.z]}
        />
      )) */}
      
      <EffectComposer>
        <Bloom luminanceThreshold={0.5} luminanceSmoothing={0.7} height={100} />
        <ChromaticAberration offset={[0.0002, 0.0002]} />
      </EffectComposer>
    </Canvas>
  );
}


function AppContent() {
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const { isLoading, isAuthenticated } = useAuth();
  const canvasRef = useRef(null);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/inspect/:buildingId/:unitId?" element={
         <Suspense fallback={<CircularProgress />}> 
           <InspectionForm />
         </Suspense>
      } />
      <Route path="/" element={
        <PrivateRoute>
          <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
            <Suspense fallback={<CircularProgress />}>
              <Scene canvasRef={canvasRef} selectedBuilding={selectedBuilding} setSelectedBuilding={setSelectedBuilding} />
            </Suspense>
            <UI selectedBuilding={selectedBuilding} setSelectedBuilding={setSelectedBuilding} />
          </div>
        </PrivateRoute>
      } />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}


function App() {
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  window.app = this;

  /* useEffect(() => {

    const controller = new AbortController();

    const checkAuth = async () => {
      try {
        //setIsAuthenticated(isAuthenticated);
	const authed = await api.checkAuth(controller.signal)
	if (!authed && window.location.pathname != '/login') {
		api.redirectToLogin();
		return false
	}
        //setIsAuthenticated(authed);
console.log('auth', authed);
      } catch (error) {
console.log('yea yeah');
        if (error.name === 'AbortError') {
          // Request was aborted, do nothing
          return;
        }
        console.error('Auth check failed:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();

    return () => {
      controller.abort();
    };

  }, []);
  */

  /*console.log('isLoading', isLoading);
  if (isLoading) {
    return <div>Loading...</div>;
  }*/

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <BrowserRouter>
            <AppContent />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );


/*
    <PrivateRoute>
      <div style={{ width: '100vw', height: '100vh', position: 'relative' }}>
	<Scene canvasRef={canvasRef} selectedBuilding={selectedBuilding} setSelectedBuilding={setSelectedBuilding} />
	<UI selectedBuilding={selectedBuilding} setSelectedBuilding={setSelectedBuilding} />
      </div>
    </PrivateRoute>

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <div style={{ width: '100vw', height: '100vh' }}>
          <Canvas>
            <PerspectiveCamera makeDefault position={[0, 50, 150]} fov={60} />
            <Suspense fallback={null}>
              <Stars radius={300} depth={50} count={5000} factor={4} saturation={0} fade />
              <Cloud opacity={0.5} speed={0.4} width={10} depth={1.5} segments={20} />
              <ambientLight intensity={0.5} />
              <pointLight position={[10, 10, 10]} />
              {selectedBuilding ? (
                <Building buildingId={selectedBuilding} setSelectedBuilding={setSelectedBuilding} />
              ) : (
                <City setSelectedBuilding={setSelectedBuilding} />
              )}
              <OrbitControls />
            </Suspense>
          </Canvas>
          <UI selectedBuilding={selectedBuilding} setSelectedBuilding={setSelectedBuilding} />
        </div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <div style={{ width: '100vw', height: '100vh' }}>
          <Canvas camera={{ position: [0, 50, 100], fov: 60 }}>
            <Suspense fallback={null}>
              <Sky sunPosition={[100, 10, 100]} />
              <Stars radius={300} depth={50} count={5000} factor={4} saturation={0} fade />
              <Cloud opacity={0.5} speed={0.4} width={10} depth={1.5} segments={20} />
              <ambientLight intensity={0.5} />
              <pointLight position={[10, 10, 10]} />
              {selectedBuilding ? (
                <Building buildingId={selectedBuilding} setSelectedBuilding={setSelectedBuilding} />
              ) : (
                <City setSelectedBuilding={setSelectedBuilding} />
              )}
              <OrbitControls />
            </Suspense>
          </Canvas>
          <UI selectedBuilding={selectedBuilding} setSelectedBuilding={setSelectedBuilding} />
        </div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={
            <div style={{ width: '100vw', height: '100vh' }}>
              <Canvas camera={{ position: [0, 50, 100], fov: 60 }}>
                <Suspense fallback={null}>
                  <Environment preset="night" />
                  <Sky sunPosition={[100, 10, 100]} />
                  <Stars radius={300} depth={50} count={5000} factor={4} saturation={0} fade />
                  <Cloud opacity={0.5} speed={0.4} width={10} depth={1.5} segments={20} />
                  <ambientLight intensity={0.5} />
                  <pointLight position={[10, 10, 10]} />
                  {selectedBuilding ? (
                    <Building buildingId={selectedBuilding} setSelectedBuilding={setSelectedBuilding} />
                  ) : (
                    <City setSelectedBuilding={setSelectedBuilding} />
                  )}
                  <EffectComposer>
                    <Bloom luminanceThreshold={0.3} luminanceSmoothing={0.9} height={300} />
                    <ChromaticAberration offset={[0.0005, 0.0005]} />
                  </EffectComposer>
                </Suspense>
              </Canvas>
              <UI selectedBuilding={selectedBuilding} setSelectedBuilding={setSelectedBuilding} />
            </div>
          } />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
*/
}


export default App;
