import React, { createContext, useState, useContext, useEffect } from 'react';
import api from './api';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [authCheckComplete, setAuthCheckComplete] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          if (decodedToken.exp * 1000 > Date.now()) {
            const result = await api.checkAuth();
            setIsAuthenticated(result);
            if (result) {
              const userData = await api.getUserData();
              setUser(userData);
            }
          } else {
            throw new Error('Token expired');
          }
        } catch (error) {
          console.error('Auth check failed:', error);
          setIsAuthenticated(false);
          localStorage.removeItem('token');
        } finally {
          setAuthCheckComplete(true);
        }
      } else {
        setIsAuthenticated(false);
        setAuthCheckComplete(true);
      }
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  const login = async (username, password) => {
    try {
      const response = await api.login(username, password);
      localStorage.setItem('token', response.access_token);
      setIsAuthenticated(true);
      const userData = await api.getUserData();
      setUser(userData);
      return true;
    } catch (error) {
      console.error('Login failed:', error);
      return false;
    }
  };

  const logout = async () => {
    try {
      await api.logout();
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      localStorage.removeItem('token');
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  const value = { isAuthenticated, isLoading, authCheckComplete, user, login, logout };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
